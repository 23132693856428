.about {
	background-image: url(../images/bg/home/8.png);
	background-repeat: no-repeat;
	background-position: top;

	background-attachment: fixed;
}

.aboutbutton {
	cursor: pointer;
}

.about-info-img {
	width: 100%;
}

.tab-list > button {
	background-color: rgba(0, 0, 0, 0);
	padding: 1rem;
}

.about-img {
	overflow: hidden;
	border-radius: 10px;
}

.exp-info {
	padding-bottom: 1rem;
	display: grid;
	grid-template-columns: 2fr 2fr;
	grid-template-rows: 0.5fr;
	gap: 1em;
	grid-template-areas:
		'exptitle exptitle'
		'. .'
		'. .';
}

.exptitle {
	grid-area: exptitle;
}

.sk-info {
	padding-bottom: 1rem;
	display: grid;
	grid-template-columns: 2fr 2fr;
	grid-template-rows: 0.5fr 0.5fr 0.5fr;
	gap: 1em 1em;
	grid-template-areas:
		'. . '
		'. . '
		'. . ';
}

@media (min-width: 35em) {
	.numbered-title {
		text-align: start;
		margin-top: 2rem;
	}
	.about-meta {
		flex-direction: row;
		justify-content: space-evenly;
	}
	.exp-info {
		grid-template-rows: 0.5fr 0.5fr;
	}
	.about-info-img {
		height: 15vh;
	}
	.sk-info {
		padding-bottom: 1rem;
		display: grid;
		grid-template-columns: 2fr 2fr 2fr;
		grid-template-rows: 0.5fr;
		gap: 1em 1em;
		grid-template-areas:
			'. . .'
			'. . .'
			'. . .';
	}
}
