/* ------------------- */
/* Custom Properties    /
/* ------------------- */

:root {
	--clr-dark: #2f3b69; /* Dark Royal Blue */
	--clr-light: #f6f5f1; /* Ivory White */
	--clr-accent-peach: #ea9e86; /* Light Peach */
	--clr-accent-salmon: #ff9470; /* Salmon Orange */
	--clr-accent-pink: #fd9497; /* Light Pink */
	--clr-accent-rouge: #e16167; /* Dark Pink */
	--clr-accent-teal: #3e829a; /* Dark Teal */

	--hsl-dark: 228 38% 30%; /* Dark Royal Blue */
	--hsl-light: 48 22% 95%; /* Ivory White */
	--hsl-accent-peach: 14 70% 72%; /* Light Peach */
	--hsl-accent-salmon: 15 100% 72%; /* Salmon Orange */
	--hsl-accent-pink: 358 96% 79%; /* Light Pink */
	--hsl-accent-rouge: 357 68% 63%; /* Dark Pink */
	--hsl-accent-teal: 196 43% 42%; /* Dark Teal */

	/* --clr-dark: 0 0% 7%;
	--clr-accent: 25 85% 50%;
	--clr-accent-2: 193 96% 58%;
	--clr-white: 0 0% 100%;
	--clr-black: 0 0% 0%;
	--clr-gray: 224 10% 20%; */

	/* font-sizes */
	--fs-1000: clamp(6rem, 2.429rem + 9.524vw, 11rem);
	--fs-900: clamp(5rem, 8vw + 1rem, 9.375rem);
	--fs-800: 4.5rem;
	--fs-700: 2.5rem;
	--fs-600: 1.75rem;
	--fs-500: 1.25rem;
	--fs-450: 1.2rem;
	--fs-400: 1.125rem;
	--fs-300: 0.9375rem;
	--fs-200: 0.875rem;

	--ff-sans-cond: 'Ovo', serif; /* Title */
	--ff-serif: 'Lora', serif; /* Subtitle*/
	--ff-sans-normal: 'Martel Sans', sans-serif; /* Span */
}

/*Tablet size*/
@media (min-width: 35em) {
	:root {
		/* font-sizes */

		--fs-800: 5rem;
		--fs-700: 3rem;
		--fs-600: 1.5rem;
		--fs-500: 1.25rem;
		--fs-450: 1.19rem;
		--fs-400: 1rem;
	}
}

/*Desktop size*/
@media (min-width: 80em) {
	:root {
		/* font-sizes */
		--fs-800: 6.25rem;
		--fs-700: 3.5rem;
		--fs-600: 2rem;
		--fs-500: 1.75rem;
		--fs-450: 1.45rem;
		--fs-400: 1.125rem;
	}
}
/* ------------------- */
/* Reset               */
/* ------------------- */

/* https://piccalil.li/blog/a-modern-css-reset/ */

/* Box sizing */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Reset margins */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	font-weight: 400;
}

/* set up the body */
body {
	font-family: var(--ff-sans-normal);
	font-size: var(--fs-400);
	color: var(--clr-dark);
	line-height: 1.5;
	min-height: 100vh;
	display: grid;
	grid-template-rows: min-content 1fr;
	overflow-x: hidden;
}

/* make images easier to work with */
img,
picture {
	max-width: 100%;
	display: block;
}

/* make form elements easier to work with */
input,
button,
textarea,
select {
	font: inherit;
}

/* remove animations for people who've turned them off */

@media (prefers-reduced-motion: reduce) {
	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

/* ------------------- */
/* Utility classes     */
/* ------------------- */

.flex {
	display: flex;
	gap: var(--gap, 1rem);
}

.f-center-vertical {
	flex-direction: column;
	align-items: center;
}

.f-center-horizontal {
	flex-direction: row;
}

.grid {
	display: grid;
	gap: var(--gap, 1rem);
}

.d-block {
	display: block;
}

/*.flow > * + * 
.flow > *:not(:first-child)
.flow > *:not(:last-child)
*/
.flow > *:where(:not(:first-child)) {
	margin-top: var(--flow-space, 1rem);
}

.container {
	padding-inline: 2em;
	margin-inline: auto;
	max-width: 80rem;
}

.min-height {
	min-height: 80vh;
}
/* Screen Reader only */
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap; /* added line */
	border: 0;
}

.skip-to-content {
	position: absolute;
	z-index: 9999;
	background: var(--clr-light);
	color: var(--clr-dark);
	padding: 0.5em 1em;
	margin-inline: auto;
	transform: translateY(-100%);
	transition: transform 250ms ease-in;
}

.skip-to-content:focus {
	transform: translateY(0);
}

/* colors */

.bg-dark {
	background-color: var(--clr-dark);
}
.bg-light {
	background-color: var(--clr-light);
}
.bg-accent {
	background-color: var(--clr-accent-peach);
}
.bg-accent-2 {
	background-color: var(--clr-accent-salmon);
}
.bg-accent-3 {
	background-color: var(--clr-accent-pink);
}
.bg-accent-4 {
	background-color: var(--clr-accent-rouge);
}
.bg-accent-5 {
	background-color: var(--clr-accent-teal);
}

.text-dark {
	color: var(--clr-dark);
}
.text-light {
	color: var(--clr-light);
}
.text-accent {
	color: var(--clr-accent-peach);
}
.text-accent-2 {
	color: var(--clr-accent-salmon);
}
.text-accent-3 {
	color: var(--clr-accent-pink);
}
.text-accent-4 {
	color: var(--clr-accent-rouge);
}
.text-accent-5 {
	color: var(--clr-accent-teal);
}

/* typography */

.ff-serif {
	font-family: var(--ff-serif);
}
.ff-sans-cond {
	font-family: var(--ff-sans-cond);
}
.ff-sans-normal {
	font-family: var(--ff-sans-normal);
}

.letter-spacing-1 {
	letter-spacing: 4.75px;
}
.letter-spacing-2 {
	letter-spacing: 2.7px;
}
.letter-spacing-3 {
	letter-spacing: 2.35px;
}

.uppercase {
	text-transform: uppercase;
}
.bold {
	font-weight: bold;
}

.text-center {
	text-align: center;
}

.fs-1000 {
	font-size: var(--fs-1000);
}
.fs-900 {
	font-size: var(--fs-900);
}
.fs-800 {
	font-size: var(--fs-800);
}
.fs-700 {
	font-size: var(--fs-700);
}
.fs-600 {
	font-size: var(--fs-600);
}
.fs-500 {
	font-size: var(--fs-500);
}
.fs-450 {
	font-size: var(--fs-450);
}
.fs-400 {
	font-size: var(--fs-400);
}
.fs-300 {
	font-size: var(--fs-300);
}
.fs-200 {
	font-size: var(--fs-200);
}

.fs-900,
.fs-800,
.fs-700,
.fs-600 {
	line-height: 1.1;
}

.numbered-title {
	text-transform: uppercase;
	font-family: var(--ff-sans-cond);
	letter-spacing: 4.75px;
	font-size: var(--fs-600);
	padding-bottom: 1rem;
}

.numbered-title span {
	margin-right: 0.5em;
	font-weight: 700;
	color: var(--clr-accent-pink);
}

.icon {
	height: 2.5rem;
}

.icon-link {
	text-decoration: none;
	color: var(--clr-dark);
}

.tab-list {
	justify-content: center;
	--gap: 1rem;
	flex-wrap: wrap;
}

.error-div {
	background-image: url(./images/bg/14.png);
	background-repeat: no-repeat;
	background-position: bottom;

	background-attachment: fixed;
}

.error-button {
	height: 5rem;
	border: none;
	border-radius: 5px;
	padding: 1rem;
	cursor: pointer;
	transition: 0.6s ease;
	background-color: var(--clr-accent-rouge);
	color: var(--clr-light);

	display: flex;
	justify-content: center;
	align-items: center;
}

.error-button:hover {
	transition: 0.6s ease;
	background-color: var(--clr-accent-pink);
}
/* ----------------------------- */
/* Layout                        */
/* ----------------------------- */

/* same thing: grid-template-columns: 2em minmax(0, 40rem) minmax(0, 40rem) 2em;*/
.grid-container {
	text-align: center;
	display: grid;
	place-items: center;
	padding-top: max(2rem, 3vh);
	padding-inline: 1rem;
	padding-bottom: 4rem;
	min-height: 90vh;
	justify-content: center;
}

.grid-container p:not([class]) {
	max-width: 50ch;
}

.numbered-title {
	grid-area: title;
}

/* about layout */

.grid-container--about {
	padding-bottom: 10rem;
	--flow-space: 2rem;
	grid-template-areas:
		'title'
		'tabs'
		'image'
		'content';
}

.grid-container--about > .about-img {
	grid-area: image;
	max-width: 80%;
}

.grid-container--about > .tab-list {
	grid-area: tabs;
}

.grid-container--about > .about-info {
	grid-area: content;
}
.grid-container--about > .exp-info {
	grid-area: content;
}
.grid-container--about > .sk-info {
	grid-area: content;
}
.about-meta {
	flex-direction: column;
	align-items: center;
}

/*Events Layout */

.grid-container--events {
	--flow-space: 2rem;
	grid-template-areas:
		'title'
		'tabs';
}
.fade {
	animation: fadeOutIn 0.5s ease-in-out;
}

@keyframes fadeOutIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.grid-container--events > .tab-list {
	grid-area: tabs;
	width: 100%;
	flex-wrap: wrap;
}

.grid-container--events > .events-details {
	grid-area: content;
}

.accordion {
	width: 100%;
}

@media (min-width: 35em) and (max-width: 69.9em) {
	.numbered-title {
		justify-self: start;
		margin-top: 2rem;
	}

	.about-meta {
		--gap: 4rem;
		flex-direction: row;
		justify-content: space-evenly;
	}

	.grid-container--events {
		padding-bottom: 10rem;
		grid-template-areas:
			'title'
			'tabs';
	}
	.accordion {
		width: 60vw;
	}
}

@media (min-width: 70em) {
	.grid-container {
		text-align: left;
		column-gap: var(--container-gap, 2rem);
		grid-template-columns: minmax(2rem, 1fr) repeat(2, minmax(0, 40rem)) minmax(
				2rem,
				1fr
			);
	}

	.grid-container--home {
		padding-bottom: max(4rem, 12vh);
		align-items: start;
	}

	.grid-container--home > *:first-child {
		grid-column: 2;
	}

	.grid-container--home > *:last-child {
		grid-column: 3;
	}

	.grid-container--about {
		justify-items: start;
		align-content: start;
		grid-template-areas:
			'. title title .'
			'. tabs tabs .'
			'. image content .';
	}

	.grid-container--about > img {
		max-width: 90%;
	}

	.about-meta {
		--gap: min(4rem, 6rem);
		text-align: center;
		justify-content: center;
	}

	.grid-container--events {
		justify-items: start;
		align-content: start;
		grid-template-areas:
			'. title title .'
			'. tabs tabs .';
	}

	.grid-container--events > .dot-indicators {
		justify-self: start;
	}

	.events-img {
		width: 100%;
	}
	.accordion {
		width: 25vw;
		height: 100%;
	}
}

.AppBackground {
	min-height: 100vh;
	background-color: hsl(var(--hsl-light) / 1);
	/* background-image: var(--bg-image);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 85vw; */
}

/* ----------------------------- */
/* Page specific background      */
/* ----------------------------- */

/*for main Title: tere was here*/
.main-title {
	font-size: clamp(8rem, 5.429rem + 9.524vw, 10rem);
	line-height: clamp(6.5rem, 3.143rem + 7.619vw, 10rem);
	margin-bottom: 3rem;
}

.blur-container {
	padding-inline: 2rem;
}
@supports (backdrop-filter: blur(1rem)) {
	.blur-container {
		backdrop-filter: blur(1.5rem);
	}
}

/* Global Animations */
.rotate-center {
	-webkit-animation: rotate-center 15s linear 8 alternate forwards;
	animation: rotate-center 15s linear 8 alternate forwards;
}

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes rotate-center {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
