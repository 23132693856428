.pub-main-title {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	padding-bottom: 1rem;
}
.p-div {
	display: flex;
	align-items: flex-start;

	margin-bottom: 1rem;
	gap: 0.5rem;
	min-height: 10vh;
	justify-content: space-between;
}
.p-likes {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.p-title {
	text-decoration: underline;
}

.p-likes-text {
	font-weight: bold;
}

.p-link {
	color: var(--clr-dark);
}

.p-link:hover {
	color: var(--clr-accent-teal);
}
