.home-intro {
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	min-height: 80vh;
}

.intro-img {
	height: 100%;
	max-width: 95vw;
	z-index: 1;
}

.pinkCloud {
	position: absolute;
	max-height: 100%;
	z-index: 10;
	-webkit-animation: rotate-center 15s linear 6 alternate forwards;
	animation: rotate-center 15s linear 6 alternate forwards;
}

.title {
	position: absolute;
	z-index: 100;
	text-align: center;
}
