.projects-container {
	flex-direction: column;
	background-image: url(../images/bg/home/9.png);
	background-repeat: no-repeat;
	background-position: top;
	background-attachment: fixed;
	overflow: visible;
	padding-inline: 2rem;
	width: 100%;
}

.proj-content {
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	padding-bottom: 10vh;
}
.proj-title {
	text-align: start;
}
.pr-link {
	text-decoration: none;
}
.pt-container {
	backdrop-filter: blur(5px);
	background-color: hsl(var(--hsl-light) / 0.4);
	width: 100%;
	border-radius: 10px;
	border: 1px solid var(--clr-dark);
	padding: 2rem;
	transition: all 0.5s ease;
	justify-content: center;
}

.pt-container:hover {
	transition: all 0.5s ease;
	background-color: hsl(var(--hsl-accent-teal) / 0.4);
}

@media (min-width: 45em) {
	.pt-container {
		width: 40%;
		height: 30rem;
	}
}

@media (min-width: 85em) {
	.projects-container {
		flex-wrap: nowrap;
		min-height: 80vh;
	}
	.pt-container {
		width: 20%;
		height: 30rem;
	}
}
