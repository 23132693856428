.dt-div {
	background-image: url(../images/bg/home/10.png);
	background-repeat: no-repeat;
	background-position: top;
	background-attachment: fixed;
	padding-inline: 2rem;
	flex-direction: column;
	padding-bottom: 10vh;
}

.navlink {
	text-decoration: none;
	color: var(--clr-accent-peach);
	transition: all 0.3s ease-in-out;
}

.navlink:hover {
	color: var(--clr-dark);
	transition: all 0.3s ease-in-out;
}

.BackIconSizeContact {
	transform: scale(1.5);
}

.DemoLinks {
	margin: 0.3em 0;
	color: var(--clr-accent-teal);
	font-weight: bold;
	width: fit-content;
	height: fit-content;
	transition: all 0.3s ease-in-out;
}

.DemoLinks:hover {
	color: var(--clr-dark);
	transition: all 0.3s ease-in-out;
}
.dt-links {
	display: flex;
	flex-direction: row;
	justify-content: center;
	gap: 1rem;
	text-align: center;
}

.dt-img {
	display: flex;
	width: 80%;
}

.dt-content {
	justify-content: center;
	flex-direction: column;
	width: 100%;
	text-align: center;
}
.dt-button {
	width: fit-content;
	background-color: var(--clr-accent-teal);
	color: var(--clr-light);
	border: none;
	border-radius: 5px;
	margin: 0.3rem;
	padding: 0.3rem 0.5rem;
}

.pallete {
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

@media (min-width: 55em) {
	.dt-content {
		width: 60%;
	}
}
