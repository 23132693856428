.footer {
	--gap: 0.5rem;
	background: hsl(var(--hsl-light) / 1);
	position: relative;
	width: 100%;
	bottom: 0;
}

@supports (backdrop-filter: blur(1rem)) {
	.footer {
		background: hsl(var(--hsl-light) / 1);
		backdrop-filter: blur(1.5rem);
	}
}

.icon-link {
	color: var(--clr-dark);
	transition: all 0.3s ease;
}

.icon-link:hover {
	color: var(--clr-accent-salmon);
	transition: all 0.3s ease;
}
