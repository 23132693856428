.contact-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	background-image: url(../images/bg/home/7.png);
	background-repeat: no-repeat;
	background-position: right;
	background-attachment: fixed;
}

.contact-content {
	flex: 1;
	z-index: 100;
	flex-direction: column;
	align-items: start;
	text-align: start;
	margin: 2rem;
}

.contact-img {
	flex: 1;
	width: 100%;
}
.contact-span {
	text-align: start;
}
.contact-form {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
	width: 80%;
	margin: 2rem;
}

.contact-form > input {
	height: 3rem;
	margin-bottom: 0.5rem;
	border: 0.1rem solid var(--clr-dark);
	border-radius: 10px;
	padding: 0.5rem;
	background-color: none;
	color: var(--clr-dark);
}

.contact-form > textarea {
	height: 10rem;
	border: 0.1rem solid var(--clr-dark);
	border-radius: 10px;
	padding: 0.5rem;
	resize: none;
	background-color: none;
	color: var(--clr-dark);
	margin-bottom: 1.5rem;
}

input::placeholder,
textarea::placeholder {
	color: var(--clr-dark);
}

.contact-form > button {
	height: 3rem;
	border: none;
	border-radius: 10px;
	font-size: 1.4rem;
	padding: 0.5rem;
	cursor: pointer;
	transition: 0.6s ease;
	background-color: var(--clr-accent-teal);
	color: var(--clr-light);
}

.contact-form > button:hover {
	transition: 0.6s ease;
	background-color: var(--clr-accent-rouge);
}

.contact-button {
	background-color: var(--clr-dark);
	color: var(--clr-light);
	font-size: 1rem;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	place-items: center;
	padding: 0 2em;
	border-radius: 50%;
	aspect-ratio: 1;
	text-decoration: none;
	text-align: center;
	width: 8rem;
	height: 8rem;
}

.contact-button::after {
	content: '';
	position: absolute;
	z-index: -1;
	background: var(--clr-dark);
	width: 100%;
	height: 100%;
	border-radius: inherit;
	opacity: 0;
	transition: opacity 500ms linear, transform 750ms ease-in-out;
}

.contact-button:hover::after,
.contact-button:focus::after {
	opacity: 1;
	transform: scale(1.5);
}

.contact-blur-container {
	background: var(--clr-light);
	padding-inline: 2rem;
}
@supports (backdrop-filter: blur(1rem)) {
	.contact-blur-container {
		background: var(--clr-light);
		backdrop-filter: blur(2rem);
	}
}
#overlay {
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	background: var(--clr-accent-peach);
	width: 100%;
	height: 100%;
	opacity: 0.5;
	z-index: 100;
}

.result-popup {
	display: none;
	/* position: absolute;
	top: 50%;
	left: 50%; */

	position: fixed !important;
	top: 48% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	background-color: var(--clr-light);
	width: 80vw;
	z-index: 200;
	justify-content: center;
	flex-direction: column;
	border-radius: 10px;
	padding: 2rem;
}

.result-content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.result-span {
	width: 80%;
	text-align: center;
}
.result-img {
	width: 100%;
	height: 10rem;
}

.popupcontrols {
	display: flex;
	justify-content: flex-end;
}

.popupCloseButton {
	height: 2rem;
	border: none;
	border-radius: 5px;

	cursor: pointer;
	transition: 0.6s ease;
	background-color: var(--clr-accent-rouge);
	color: var(--clr-light);

	display: flex;
	justify-content: center;
	align-items: center;
}

.popupCloseButton:hover {
	transition: 0.6s ease;
	background-color: var(--clr-accent-pink);
}
@media (min-width: 35em) {
	.contact-links {
		flex-direction: row;
	}
	.contact-span {
		width: 80%;
	}
	.result-popup {
		width: 30rem;
	}
}

@media (min-width: 65em) {
	.contact-button {
		background-color: var(--clr-dark);
		color: var(--clr-light);
	}

	.contact-button::after {
		background: hwb(0 100% 0% / 0.281);
	}

	.contact-container {
		flex-wrap: nowrap;
		min-height: 80vh;
	}
	.contact-content {
		width: 50%;
	}
	.result-div {
		justify-content: center;
		margin: 4rem;
	}
}
