.primary-navigation {
	--gap: clamp(1rem, 2.5vw, 1.5rem);
	--underline-gap: 2rem;
	list-style: none;
	padding: min(20rem, 15vh) 0.5rem;
	margin: 0;
}

.primary-navigation a {
	text-decoration: none;
}

.primary-navigation a > span {
	margin-right: 0.5em;
}
.mobile-nav-toggle {
	display: none;
}

@supports (backdrop-filter: blur(1rem)) {
	.primary-navigation {
		backdrop-filter: blur(1.5rem);
	}
}

/* Tablet */
@media (max-width: 56.9rem) {
	.primary-navigation {
		flex-direction: column;
		--underline-gap: 0.5rem;
		inset: 0 0 0 50%;
		list-style: none;
		margin: 0;
		position: fixed;
		z-index: 2000;
		transform: translateX(100%);
		transition: transform 500ms ease-in-out;
	}

	.mobile-nav-toggle {
		cursor: pointer;
		display: block;
		position: absolute;
		z-index: 3000;
		right: 1rem;
		top: 2rem;
		background: transparent;
		border: 0;
		background-image: url(../images/shared/icon-hamburger.svg);
		background-repeat: no-repeat;
		background-position: center;
		width: 1.5rem;
		aspect-ratio: 1;
	}

	.primary-navigation[data-visible='true'] {
		transform: translateX(0);
	}

	.mobile-nav-toggle[aria-expanded='true'] {
		background-image: url(../images/shared/icon-close.svg);
	}

	.mobile-nav-toggle:focus-visible {
		outline: 5px solid black;
		outline-offset: 5px;
	}
}

/* Laptop */
@media (min-width: 57em) and (max-width: 64.9em) {
	nav {
		order: 2;
	}
	.primary-navigation {
		margin: 0;
		--underline-gap: 1rem;
		padding: 0;
		padding-inline: 0.5rem;
	}

	.primary-navigation a > span {
		display: none;
	}
}

/* Desktop */
@media (min-width: 65em) {
	nav {
		order: 2;
	}

	.primary-navigation {
		margin-block: 1rem;
		--underline-gap: 2rem;
		padding: 0;
		padding-inline: 0.5rem;
	}
}

.underline-indicators > * {
	padding: var(--underline-gap);
	border-radius: 10px;
	border: 0;
	border-inline: 0.1rem solid var(--clr-dark);
	transition: all 0.3s ease;
}

.underline-indicators > li:hover,
.underline-indicators > li:focus {
	color: var(--clr-accent-rouge);
	border-color: var(--clr-accent-peach);
	transition: all 0.3s ease;
}

.underline-indicators > button:hover,
.underline-indicators > button:focus {
	color: var(--clr-accent-rouge);
	border-color: var(--clr-accent-peach);
	transition: all 0.3s ease;
}

.underline-indicators > .active,
.underline-indicators > [aria-selected='true'],
.underline-indicators > [aria-pressed='true'] {
	color: var(--clr-dark);
	border-color: var(--clr-accent-pink);
}
