/* ------------------- */
/* Compontents         */
/* ------------------- */
.line {
	display: none;
}

/* primary-header */

.logo {
	margin: 1.5rem clamp(1.5rem, 5vw, 3.5rem);
	max-height: 10vh;
}

.logo:hover {
	animation: rotate-center 15s linear;
}

.primary-header {
	justify-content: space-between;
	align-items: center;
}
/*--gap: 8rem;*/

.design-system {
	--gap: 8rem;
	width: 100%;
	justify-content: center;
}

.mobile-nav-toggle {
	display: none;
}

@media (max-width: 35rem) {
	.mobile-nav-toggle {
		cursor: pointer;
		display: block;
		position: absolute;
		z-index: 2000;
		right: 1rem;
		top: 2rem;
		background: transparent;
		border: 0;
		background-image: url(../images/shared/icon-hamburger.svg);
		background-repeat: no-repeat;
		background-position: center;
		width: 1.5rem;
		aspect-ratio: 1;
	}

	.mobile-nav-toggle[aria-expanded='true'] {
		background-image: url(../images/shared/icon-close.svg);
	}

	.mobile-nav-toggle:focus-visible {
		outline: 5px solid white;
		outline-offset: 5px;
	}
}
