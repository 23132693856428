.pt-div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
}

.img-div {
	flex: 1;
	justify-content: center;
	align-items: start;
}

.pt-img {
	overflow: hidden;
	border-radius: 5px;
}

.pt-info {
	flex: 1;
	gap: 0.3rem;
	z-index: 3;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	transition: all 0.5s ease;
	border-radius: 5px;
}

.pt-button {
	background-color: var(--clr-dark);
	color: var(--clr-light);
	border: none;
	border-radius: 5px;
	margin: 0.2rem;
	padding: 0.2rem 0.3rem;
}

@media (min-width: 35em) {
	.pt-img {
		height: 12rem;
	}
}
